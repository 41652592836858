const Inter = [
  {
    space_src: "baidujs",
    space_type: "inters",
    space_id: "u6919196",
    space_js: "//cfpbday.dimenspace.com/site/t_onwxw/common/x/resource/wn.js"
  }
];

const Native1 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6919197",
    space_js: "//cfpbday.dimenspace.com/site/upox/common/y/openjs/x_yxq.js"
  }
];

const Native2 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6919198",
    space_js: "//cfpbday.dimenspace.com/source/v/common/qpy/production/z/yzys.js"
  }
];

const Native3 = [
  {
    space_src: "baidujs",
    space_type: "native",
    space_id: "u6919199",
    space_js: "//cfpbday.dimenspace.com/common/w_rqz/aza/openjs/z/z.js"
  }
];

export default {
  Home_inter: Inter,
  Home_native: Native2,
  Home_banner: Native3,
  Detail_inter: Inter,
  Detail_native: Native2,
  Detail_banner: Native3,
  List_native: Native2,
  List_banner: Native3,
  Result_inter: Inter,
  Result_native: Native2,
  Result_banner: Native3
};
